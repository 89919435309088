import {
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Input,
  Row,
  Spin,
  InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import { debounce, isEqual, sortBy } from "lodash";
import moment from "moment";
import { generateQueryParamsFromPayload } from "../utils/helpers";
import { useSearchParams } from "react-router-dom";
import { IGNORED_FILTERS_IN_URL } from "../utils/constants";

const { RangePicker } = DatePicker;

const useTableColumnFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterInputValue, setFilterInputValue] = useState("");
  const [openFilter, setOpenFilter] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [rangeFilter, setRangeFilter] = useState({
    from: undefined,
    to: undefined,
  });
  const [dateRange, setDateRange] = useState();
  const [tempDebounce, setTempDebounce] = useState();
  const payloadObject = {};
  for (let p of searchParams) {
    if (
      !IGNORED_FILTERS_IN_URL.filter((item) =>
        !window.location.pathname.includes("/standalone")
          ? item !== "status"
          : true
      ).includes(p?.[0])
    ) {
      payloadObject[p?.[0]] = p[1];
    }
  }

  useEffect(() => {
    if (openFilter && payloadObject[openFilter] !== filterInputValue) {
      setFilterInputValue(payloadObject[openFilter] || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFilter, searchParams]);

  const getFilterParams = ({
    payload,
    setPayload,
    paramKey,
    inputPlaceholder,
    selection,
    date,
    menuItems,
    loading,
    checkboxColSpan,
    dateParamPrefix,
    paramPrefix,
    range,
  }) => {
    const handleFilterApply = (value) => {
      if (date) {
        let payloadObject = {
          ...payload,
          page: 1,
        };
        if (dateParamPrefix) {
          payloadObject = {
            ...payloadObject,
            [`${dateParamPrefix}_start_date`]: value?.[0]
              ? moment(value[0]).format("YYYY-MM-DD")
              : undefined,
            [`${dateParamPrefix}_end_date`]: value?.[1]
              ? moment(value[1]).format("YYYY-MM-DD")
              : undefined,
          };
        } else {
          payloadObject = {
            ...payloadObject,
            start_date: value?.[0]
              ? moment(value[0]).format("YYYY-MM-DD")
              : undefined,
            end_date: value?.[1]
              ? moment(value[1]).format("YYYY-MM-DD")
              : undefined,
          };
        }
        setSearchParams(generateQueryParamsFromPayload(payloadObject));
        setOpenFilter();
        return;
      }
      if (range) {
        let payloadObject = {
          ...payload,
          page: 1,
          [`${paramPrefix}_from`]: value?.from ? value?.from : undefined,
          [`${paramPrefix}_to`]: value?.to ? value?.to : undefined,
        };
        setSearchParams(generateQueryParamsFromPayload(payloadObject));
        setOpenFilter();
        return;
      }
      if (
        !selection &&
        payload?.[paramKey]?.toLowerCase() !== value?.toLowerCase()
      ) {
        const payloadObject = {
          ...payload,
          page: 1,
          [paramKey]: value || undefined,
        };
        setSearchParams(generateQueryParamsFromPayload(payloadObject));
        return;
      }
      if (
        selection &&
        (!isEqual(sortBy(filterInputValue), sortBy(payload?.[paramKey])) ||
          !value)
      ) {
        const payloadObject = {
          ...payload,
          page: 1,
          [paramKey]: value || undefined,
        };
        setSearchParams(generateQueryParamsFromPayload(payloadObject));
        setOpenFilter();
      }
    };

    const debounced = debounce(handleFilterApply, 1000);

    return {
      filterDropdown: range ? (
        <>
          <div className="range-filter">
            <InputNumber
              value={rangeFilter?.from}
              onChange={(val) =>
                setRangeFilter((prev) => {
                  return { ...prev, from: val };
                })
              }
              min={0}
              style={{ width: "40%" }}
            />
            <span>To</span>
            <InputNumber
              value={rangeFilter?.to}
              onChange={(val) =>
                setRangeFilter((prev) => {
                  return { ...prev, to: val };
                })
              }
              min={rangeFilter?.from}
              style={{ width: "40%" }}
            />
          </div>
          <div className="ant-table-filter-dropdown-btns">
            <button
              type="button"
              className="ant-btn ant-btn-link ant-btn-sm"
              disabled={
                !(
                  payload?.[`${paramPrefix}_from`] &&
                  payload?.[`${paramPrefix}_to`]
                )
              }
              onClick={() => {
                handleFilterApply();
              }}
            >
              Reset
            </button>
            <button
              type="button"
              className="ant-btn ant-btn-primary ant-btn-sm"
              onClick={() => {
                handleFilterApply(rangeFilter);
              }}
            >
              Apply
            </button>
          </div>
        </>
      ) : date ? (
        <>
          <div className="ant-table-filter-dropdown-search">
            <RangePicker
              id={paramKey}
              autoFocus
              className="table-dropdown-filter"
              value={dateRange}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, "days"),
                  moment().subtract(1, "days"),
                ],
                "Last 7 Days": [moment().subtract(6, "d"), moment()],
                "Last 30 Days": [moment().subtract(29, "d"), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                "Last Month": [
                  moment().subtract(1, "months").startOf("month"),
                  moment().subtract(1, "months").endOf("month"),
                ],
              }}
              onChange={(values) => {
                if (values) {
                  setDateRange(values);
                } else {
                  setDateRange();
                }
              }}
            />
          </div>
          <div className="ant-table-filter-dropdown-btns">
            <button
              type="button"
              className="ant-btn ant-btn-link ant-btn-sm"
              disabled={
                !dateParamPrefix
                  ? !(payload?.["start_date"] && payload?.["end_date"])
                  : !(
                      payload?.[`${dateParamPrefix}_start_date`] &&
                      payload?.[`${dateParamPrefix}_end_date`]
                    )
              }
              onClick={() => {
                handleFilterApply();
              }}
            >
              Reset
            </button>
            <button
              type="button"
              className="ant-btn ant-btn-primary ant-btn-sm"
              onClick={() => {
                handleFilterApply(dateRange);
              }}
            >
              Apply
            </button>
          </div>
        </>
      ) : !selection ? (
        <>
          <div className="ant-table-filter-dropdown-search">
            <Input
              id={paramKey}
              autoFocus={openFilter === paramKey}
              prefix={<SearchOutlined />}
              className="ant-table-filter-dropdown-search-input"
              placeholder={inputPlaceholder}
              value={filterInputValue || ""}
              onChange={(event) => {
                setFilterInputValue(event.target.value);
                if (tempDebounce) {
                  tempDebounce();
                }
                debounced(event.target.value);
                setTempDebounce(() => () => debounced.cancel());
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="ant-table-filter-dropdown-search">
            <Input
              id={paramKey}
              prefix={<SearchOutlined />}
              className="ant-table-filter-dropdown-search-input"
              placeholder={"Search in filters"}
              value={searchValue || ""}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </div>
          <Spin spinning={loading}>
            {menuItems?.filter((item) =>
              item?.label?.toLowerCase()?.includes(searchValue?.toLowerCase())
            )?.length > 0 ? (
              <div className="filter-list">
                <Checkbox
                  className="select-all-checkbox"
                  indeterminate={
                    filterInputValue &&
                    filterInputValue?.split(",")?.filter((value) => value)
                      ?.length !== menuItems.length &&
                    filterInputValue?.split(",")?.filter((value) => value)
                      ?.length !== 0
                  }
                  checked={
                    filterInputValue?.split(",")?.filter((value) => value)
                      ?.length === menuItems.length
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      setFilterInputValue(
                        menuItems.map((item) => item.value).join(",")
                      );
                    } else {
                      setFilterInputValue("");
                    }
                  }}
                >
                  Select All
                </Checkbox>
                <Row gutter={[4, 8]}>
                  {menuItems
                    ?.filter((item) =>
                      item?.label
                        ?.toLowerCase()
                        ?.includes(searchValue?.toLowerCase())
                    )
                    ?.map((item) => (
                      <Col key={item.value} span={checkboxColSpan || 24}>
                        <Checkbox
                          key={item.value}
                          value={item.value}
                          checked={
                            filterInputValue &&
                            filterInputValue
                              ?.split(",")
                              ?.findIndex((value) => value === item.value) !==
                              -1
                          }
                          onChange={(event) => {
                            if (event.target.checked) {
                              setFilterInputValue((prevFilter) => {
                                const filterArray = prevFilter
                                  .split(",")
                                  .filter((value) => value);
                                filterArray.push(item.value);
                                return filterArray.join(",");
                              });
                            } else {
                              setFilterInputValue((prevValues) => {
                                let filterArray = prevValues.split(",");
                                filterArray = filterArray.filter(
                                  (value) => item.value !== value
                                );
                                return filterArray.join(",");
                              });
                            }
                          }}
                        >
                          {item.label}
                        </Checkbox>
                      </Col>
                    ))}
                </Row>
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Spin>
          <div className="ant-table-filter-dropdown-btns">
            <button
              type="button"
              className="ant-btn ant-btn-link ant-btn-sm"
              disabled={
                selection
                  ? (payload?.[paramKey] && !payload?.[paramKey]?.length > 0) ||
                    !payload?.[paramKey]
                  : !payload?.[paramKey]?.trim()
              }
              onClick={() => {
                handleFilterApply();
              }}
            >
              Reset
            </button>
            <button
              type="button"
              className="ant-btn ant-btn-primary ant-btn-sm"
              onClick={() => {
                handleFilterApply(filterInputValue);
              }}
            >
              Apply
            </button>
          </div>
        </>
      ),
      className:
        (date &&
          !dateParamPrefix &&
          payload?.["start_date"] &&
          payload?.["end_date"]) ||
        (date &&
          dateParamPrefix &&
          payload?.[`${dateParamPrefix}_start_date`] &&
          payload?.[`${dateParamPrefix}_end_date`]) ||
        (range &&
          paramPrefix &&
          payload?.[`${paramPrefix}_from`] &&
          payload?.[`${paramPrefix}_to`]) ||
        (selection && payload?.[paramKey]?.length > 0) ||
        (payload?.[paramKey]?.trim && payload?.[paramKey]?.trim())
          ? "active-filter"
          : "",
      filterDropdownOpen: openFilter === paramKey,

      filterIcon: (
        <FilterFilled
          onClick={() => {
            setOpenFilter(paramKey);
          }}
        />
      ),
      onFilterDropdownOpenChange: (open) => {
        if (open) {
          setSearchValue("");
          if (date) {
            if (
              !dateParamPrefix &&
              payload?.["start_date"] &&
              payload?.["end_date"]
            ) {
              setDateRange([
                moment(payload["start_date"]),
                moment(payload["end_date"]),
              ]);
            } else if (
              dateParamPrefix &&
              payload?.[`${dateParamPrefix}_start_date`] &&
              payload?.[`${dateParamPrefix}_end_date`]
            ) {
              setDateRange([
                moment(payload[`${dateParamPrefix}_start_date`]),
                moment(payload[`${dateParamPrefix}_end_date`]),
              ]);
            } else {
              setDateRange();
            }
          }
          if (range) {
            setRangeFilter({
              from: payload[`${paramPrefix}_from`],
              to: payload[`${paramPrefix}_to`],
            });
          }
          if (!selection) {
            setTimeout(() => {
              document.getElementById(paramKey)?.focus();
            }, 50);
          }
          setFilterInputValue(
            selection ? payload?.[paramKey] || "" : payload?.[paramKey] || ""
          );
        } else {
          setOpenFilter();
        }
      },
    };
  };

  return {
    getFilterParams,
  };
};

export default useTableColumnFilter;
