import { cloneDeep, flatMap, groupBy, toLower, uniqBy } from "lodash";
import {
  ADMIN_SIDEBAR_MENU_ITEMS,
  MODULE_CATEGORIES,
  MODULE_VALUES,
  ALLOWED_MODULE_FOR_STANDALONE_CONFIGURATIONS,
  PERMISSIONS_VALUES,
  STANDALONE_SIDEBAR_MENU_ITEMS,
  IGNORED_FILTERS_IN_URL,
  ALLOWED_MODULE_FOR_LOS_CONFIGURATIONS,
} from "./constants";
import { v4 as uuid } from "uuid";
import { Base64 } from "js-base64";

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ["+1 ", "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "-";
};

export const findDuplicatesRecursive = (arr, property) => {
  const duplicates = {};
  const duplicateFields = [];

  let hasDuplicate = false;

  const traverseArray = (arr) => {
    for (const item of arr) {
      if (item && typeof item === "object") {
        const propertyValue = item[property];
        if (propertyValue) {
          const lowercaseValue = propertyValue.toLowerCase();
          duplicates[lowercaseValue] = duplicates[lowercaseValue] || [];
          duplicates[lowercaseValue].push(item);
        }
        traverseObject(item);
      }
    }
  };

  const traverseObject = (obj) => {
    for (const [, value] of Object.entries(obj)) {
      if (value && typeof value === "object") {
        traverseArray(Array.isArray(value) ? value : [value]);
      }
    }
  };

  traverseArray(arr);

  for (const [key, item] of Object.entries(duplicates)) {
    if (item?.length > 1) {
      if (!hasDuplicate) {
        hasDuplicate = true;
      }
      duplicateFields.push(key);
    }
  }

  return { duplicateFields, hasDuplicate };
};

export const arrayHasDuplicates = (array) => {
  const lowerCaseArray = array?.map((item) => toLower(item));
  const uniqueArray = uniqBy(lowerCaseArray, (item) => item);
  return uniqueArray?.length !== array?.length;
};

export const getValueForUpdateTableStage = (stage) =>
  stage?.toLowerCase() === "new order"
    ? "1"
    : stage?.toLowerCase() === "update order"
    ? "2"
    : stage?.toLowerCase() === "both"
    ? "1,2"
    : "0";

export const updateDefaultOptionProperty = (array) => {
  const tempArray = [...array];

  const allowTrueObjects = array.filter((obj) => obj.option_default === true);

  array.forEach((obj) => {
    obj.option_default = obj === allowTrueObjects[allowTrueObjects.length - 1];
  });

  return tempArray;
};

export const removeKeys = (arr, keysToRemove) => {
  return arr?.map((obj) => {
    const newObj = {};
    for (let key in obj) {
      if (!keysToRemove?.includes(key)) {
        if (Array.isArray(obj?.[key])) {
          newObj[key] = removeKeys(obj?.[key], keysToRemove);
        } else if (typeof obj?.[key] === "object" && obj?.[key] !== null) {
          newObj[key] = removeKeys([obj?.[key]], keysToRemove)[0];
        } else {
          newObj[key] = obj?.[key];
        }
      }
    }
    return newObj;
  });
};

export const downloadJSON = (data, fileName) => {
  const jsonData = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonData], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const getLocalStorageToken = () => {
  return localStorage.getItem(
    window.location.pathname.startsWith("/standalone")
      ? "standalone_token"
      : "admin_token"
  );
};

export const getDefaultToken = () => {
  if (
    window.location.pathname.startsWith("/") ||
    window.location.pathname.startsWith("/login")
  ) {
    if (localStorage.getItem("admin_token")) {
      return localStorage.getItem("admin_token");
    } else if (localStorage.getItem("standalone_token")) {
      return localStorage.getItem("standalone_token");
    } else {
      return null;
    }
  } else {
    return localStorage.getItem(
      window.location.pathname.startsWith("/standalone")
        ? "standalone_token"
        : "admin_token"
    );
  }
};

export const setLocalStorageToken = (token, tokenName) => {
  localStorage.setItem(tokenName, token);
};

export const removeLocalStorageToken = () => {
  localStorage.removeItem(
    window.location.pathname.startsWith("/standalone")
      ? "standalone_token"
      : "admin_token"
  );
};

export const setLocalStorageRememberData = (data) => {
  localStorage.setItem(
    window.location.pathname.startsWith("/standalone")
      ? "standalone_data"
      : "admin_data",
    JSON.stringify(data)
  );
};
export const getLocalStorageRememberData = () => {
  return JSON.parse(
    localStorage.getItem(
      window.location.pathname.startsWith("/standalone")
        ? "standalone_data"
        : "admin_data"
    )
  );
};

export const removeLocalStorageRememberData = () => {
  localStorage.removeItem(
    window.location.pathname.startsWith("/standalone")
      ? "standalone_data"
      : "admin_data"
  );
};

export const setLocalStorageUser = (user) => {
  localStorage.setItem(
    window.location.pathname.startsWith("/standalone")
      ? "standalone_user"
      : "admin_user",
    JSON.stringify(user)
  );
};
export const setLocalStorageUserByConfiguration = (user, configuration) => {
  localStorage.setItem(configuration, JSON.stringify(user));
};
export const getLocalStorageUser = () => {
  return JSON.parse(
    localStorage.getItem(
      window.location.pathname.startsWith("/standalone")
        ? "standalone_user"
        : "admin_user"
    )
  );
};

export const removeLocalStorageUser = () => {
  localStorage.removeItem(
    window.location.pathname.startsWith("/standalone")
      ? "standalone_user"
      : "admin_user"
  );
};

export const generateAddressFromSubjectProperty = (subjectProperty) => {
  let address = "";

  const filteredAddressFields = Object?.keys(subjectProperty)?.filter(
    (addressField) => addressField !== "property_type"
  );

  if (subjectProperty && filteredAddressFields?.length > 0) {
    filteredAddressFields?.forEach((addressField, index) => {
      if (subjectProperty?.[addressField]) {
        address += subjectProperty?.[addressField];
        if (index !== filteredAddressFields?.length - 1) {
          address += ", ";
        } else if (
          index === filteredAddressFields?.length - 1 &&
          subjectProperty?.property_type
        ) {
          address += ` (${subjectProperty?.property_type})`;
        }
      }
    });
  }
  return address;
};

export const isElementChangedForFieldMappingTable = (
  actualElement,
  updateElement
) => {
  let isElementChanged = false;
  ["element_name", "element_type", "provider_id"].forEach((key) => {
    if (
      actualElement?.[key]?.toLowerCase() !==
      updateElement?.[key]?.toLowerCase()
    ) {
      isElementChanged = true;
    }
  });
  return isElementChanged;
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const checkIfRouteIsAvailable = (menuItems, pathname) => {
  for (const route of menuItems) {
    if (route.route === pathname) {
      return true;
    }
    if (route.children?.length > 0) {
      const isChildRouteAvailable = checkIfRouteIsAvailable(
        route.children,
        pathname
      );
      if (isChildRouteAvailable) {
        return true;
      }
    }
  }
  return false;
};

export const filterModule = (arr, condition) =>
  arr.filter((item) => condition(item));

export const checkPermissions = (
  permission,
  assignedModulePermissions,
  role
) => {
  if (role === "admin") {
    return true;
  }

  if (!permission || !assignedModulePermissions) {
    return false;
  }

  if (Array.isArray(permission)) {
    const isArrayPermissions = Array.isArray(assignedModulePermissions);

    return permission.some((permissionValue) => {
      return isArrayPermissions
        ? assignedModulePermissions.some(
            (modulePermission) =>
              modulePermission?.permission === permissionValue
          )
        : assignedModulePermissions?.permission === permissionValue;
    });
  }

  return assignedModulePermissions?.permission === permission;
};

export const hasCRUDPermissions = (assignedModulePermission, role) => {
  return {
    [PERMISSIONS_VALUES.FULL]:
      role === "admin"
        ? true
        : assignedModulePermission?.permission === PERMISSIONS_VALUES.FULL,
    [PERMISSIONS_VALUES.VIEW_ONLY]:
      role === "admin"
        ? true
        : assignedModulePermission?.permission !== PERMISSIONS_VALUES.NONE,
    [PERMISSIONS_VALUES.ADD_UPDATE_ONLY]:
      role === "admin"
        ? true
        : assignedModulePermission?.permission ===
            PERMISSIONS_VALUES.ADD_UPDATE_ONLY ||
          assignedModulePermission?.permission === PERMISSIONS_VALUES.FULL,
    [PERMISSIONS_VALUES.NONE]:
      role === "admin"
        ? false
        : assignedModulePermission?.permission === PERMISSIONS_VALUES.NONE,
  };
};

export const generateRoute = (
  routes,
  role,
  permissions,
  isViewMoreMenuOpen,
  navigate,
  setCollapsed,
  configuration
) => {
  const processRoutes = (routes, configuration) => {
    return routes
      .filter((route) => {
        if (
          role === "standalone" ||
          role === "standalone_user" ||
          role === "public"
        ) {
          return role === "standalone_user"
            ? route.module !== "user" && route.module !== "address"
            : true;
        } else {
          return route.module !== MODULE_VALUES.DASHBOARD
            ? checkPermissions(
                Object.values(PERMISSIONS_VALUES).filter(
                  (permissionValue) =>
                    permissionValue !== PERMISSIONS_VALUES.NONE
                ),
                Array.isArray(route.module)
                  ? route?.module?.map((module) =>
                      permissions?.find(
                        (modulePermission) => modulePermission.module === module
                      )
                    )
                  : permissions?.find(
                      (modulePermission) =>
                        modulePermission.module === route.module
                    ),
                role
              )
            : true;
        }
      })
      .filter((route) => {
        if (route?.module === "bestx" || route?.module === "addresses")
          return true;
        if (configuration === "standalone") {
          if (Array.isArray(route.module)) {
            return route.module.some((module) =>
              ALLOWED_MODULE_FOR_STANDALONE_CONFIGURATIONS.includes(module)
            );
          } else {
            return ALLOWED_MODULE_FOR_STANDALONE_CONFIGURATIONS.includes(
              route.module
            );
          }
        } else {
          if (Array.isArray(route.module)) {
            return route.module.some((module) =>
              ALLOWED_MODULE_FOR_LOS_CONFIGURATIONS.includes(module)
            );
          } else {
            return ALLOWED_MODULE_FOR_LOS_CONFIGURATIONS.includes(route.module);
          }
        }
        return true;
      })
      .map((route) => ({
        ...route,
        title: route.title,
        label:
          route.label === "More" && isViewMoreMenuOpen ? "Less" : route.label,
        icon:
          route.label === "More" && isViewMoreMenuOpen ? (
            <i className="fa-solid fa-angle-down fa-rotate-180"></i>
          ) : (
            route.icon
          ),
        className: route?.className || "",
        onClick: route.route
          ? () => {
              navigate(route.route);
              if (setCollapsed) {
                setCollapsed();
              }
            }
          : undefined,
        children:
          route?.children?.length > 0
            ? processRoutes(route.children, configuration)
            : undefined,
      }));
  };

  return processRoutes(cloneDeep(routes), configuration);
};

export const isXMLString = (str) => {
  // Define a regular expression pattern for basic XML structure
  const xmlPattern =
    /^<\?xml\s+version=(['"])[^\1]+\1\s*\?>|<([a-zA-Z][-a-zA-Z0-9:_]*(\s+[a-zA-Z][-a-zA-Z0-9:_]*\s*=\s*(['"]).*?\4)*\s*\/?)>|<\/([a-zA-Z][-a-zA-Z0-9:_]*)\s*>$/;

  // Use the RegExp test method to check if the string matches the pattern
  return xmlPattern.test(str);
};

export const isJSONString = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const generateNewSection = () => {
  const DEFAULT_SECTION_FORMAT = {
    section_label: "",
    section_id: uuid(),
    section_type: "generic",
    elements: [
      {
        element_name: "",
        element_id: uuid(),
        label: "",
        element_type: null,
        provider_id: "",
        formatting: null,
        validation: "",
        options: [],
        updatable_stages: null,
        required: false,
        parent_element: null,
        parent_value: null,
        child: 0,
        user_value: "",
      },
    ],
  };

  return DEFAULT_SECTION_FORMAT;
};

export const getPayloadType = (payload) => {
  if (typeof payload === "object") {
    return "json";
  } else if (typeof payload === "string") {
    if (isXMLString(payload)) {
      return "xml";
    } else if (isJSONString(payload)) {
      return "json";
    }
  }
  return "string";
};

export const categorizeRoutes = (
  userRole,
  userPermissions,
  navigate,
  configuration
) =>
  groupBy(
    flatMap(
      generateRoute(
        cloneDeep(
          window.location.pathname.startsWith("/standalone")
            ? [
                ...cloneDeep(STANDALONE_SIDEBAR_MENU_ITEMS),
                {
                  key: "1",
                  icon: <i className="fa-solid fa-bell"></i>,
                  label: "Notifications",
                  route: "/standalone/notifications",
                  category: MODULE_CATEGORIES.NOTIFICATIONS.value,
                  module: MODULE_VALUES.DASHBOARD,
                },
              ]
            : ADMIN_SIDEBAR_MENU_ITEMS
        ),
        userRole,
        userPermissions,
        false,
        navigate
      ),
      (item) =>
        item.children?.length > 0
          ? item.children?.map((nestedArrItem) => ({
              ...nestedArrItem,
              icon: nestedArrItem?.label?.toLowerCase().includes("setup")
                ? item.icon
                : nestedArrItem.icon,
            }))
          : item
    )
      .filter((route) => route?.category)
      .filter((route) =>
        configuration === "standalone"
          ? ALLOWED_MODULE_FOR_STANDALONE_CONFIGURATIONS?.includes(route.module)
          : true
      ),
    "category"
  );

export const addEllipsis = (str, maxLength) => {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`;
  }
  return str;
};

export const isModuleAvailableForStandaloneConfiguration = (module) =>
  ALLOWED_MODULE_FOR_STANDALONE_CONFIGURATIONS.includes(module);

export const generateQueryParamsFromPayload = (payload) => {
  const searchParamsObject = {};
  Object?.keys(payload)?.forEach((key) => {
    if (
      typeof payload?.[key] !== "undefined" &&
      !IGNORED_FILTERS_IN_URL.filter((item) =>
        !window.location.pathname.includes("/standalone")
          ? item !== "status"
          : true
      )?.includes(key)
    ) {
      searchParamsObject[key] = payload[key];
    }
  });

  return searchParamsObject;
};

export const currencyFormatter = (amount, currency = "USD") =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(amount);

export const currencyFormatterLoan = (amount, currency = "USD") =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: +amount % 1 === 0 ? 0 : 2,
    maximumFractionDigits: +amount % 1 === 0 ? 0 : 2,
  }).format(amount);

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export const formatPhoneNumberForInput = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

export const areAllFieldsEmpty = (arr) =>
  arr?.every((obj) =>
    Object.values(obj)?.every((value) => !value || !value?.trim())
  );

const adjustKeyLength = (key, length) => {
  const enc = new TextEncoder();
  const keyBuffer = enc.encode(key);

  if (keyBuffer.length < length) {
    const paddedKey = new Uint8Array(length);
    paddedKey.set(keyBuffer);
    return paddedKey;
  } else if (keyBuffer.length > length) {
    return keyBuffer.slice(0, length);
  }
  return keyBuffer;
};

export const encryptData = async (data, secretKey) => {
  const keyBuffer = adjustKeyLength(secretKey, 32);

  // Generate a random IV
  const iv = crypto.getRandomValues(new Uint8Array(16));

  // Import the key
  const key = await crypto.subtle.importKey(
    "raw",
    keyBuffer,
    { name: "AES-CBC" },
    false,
    ["encrypt"]
  );

  // Convert the data to an ArrayBuffer
  const enc = new TextEncoder();
  const dataBuffer = enc.encode(JSON.stringify(data));

  // Encrypt the data
  const encryptedBuffer = await crypto.subtle.encrypt(
    {
      name: "AES-CBC",
      iv: iv,
    },
    key,
    dataBuffer
  );

  // Convert the encrypted data and IV to base64 and concatenate with '::'
  const encryptedBase64 = Base64.fromUint8Array(
    new Uint8Array(encryptedBuffer)
  );
  const ivBase64 = Base64.fromUint8Array(iv);
  return `${encryptedBase64}::${ivBase64}`;
};

export const decryptData = async (encryptedData, secretKey) => {
  const keyBuffer = adjustKeyLength(secretKey, 32);

  // Split the encrypted data and IV using '::' delimiter
  const [encryptedBase64, ivBase64] = encryptedData.split("::");

  if (!encryptedBase64 || !ivBase64) {
    throw new Error(
      "Invalid encrypted data format. Ensure the data includes both encrypted data and IV."
    );
  }

  // Convert the base64 strings back to Uint8Array
  const encryptedArray = Base64.toUint8Array(encryptedBase64);
  const iv = Base64.toUint8Array(ivBase64);

  // Import the key
  const key = await crypto.subtle.importKey(
    "raw",
    keyBuffer,
    { name: "AES-CBC" },
    false,
    ["decrypt"]
  );

  // Decrypt the data
  const decryptedBuffer = await crypto.subtle.decrypt(
    {
      name: "AES-CBC",
      iv: iv,
    },
    key,
    encryptedArray
  );

  // Convert the decrypted ArrayBuffer to JSON
  const dec = new TextDecoder();
  const decryptedData = dec.decode(decryptedBuffer);

  return JSON.parse(decryptedData);
};
